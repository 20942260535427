import image1 from "../Assets/portifolio/casa-de-condominio-alto-padrao-arcbio-02.png";
import image2 from "../Assets/portifolio/casa-de-condominio-alto-padrao-arcbio-01.png";
import image3 from "../Assets/portifolio/chale-de-praia-rustico-03.jpg";
import image4 from "../Assets/72.jpeg";
import image5 from "../Assets/portifolio/chale-de-praia-rustico-02.jpg";
import image6 from "../Assets/portifolio/residencia-rustica-alto-padrao-arcbio-01.jpg";
import image7 from "../Assets/portifolio/residencia-rustica-alto-padrao-arcbio-02.jpg";
import image8 from "../Assets/portifolio/residencia-rustica-alto-padrao-arcbio-03.jpg";
import image9 from "../Assets/portifolio/residencia-rustica-alto-padrao-arcbio-04.jpg";
import image10 from "../Assets/portifolio/resort-alto-padrao-design-rustico-arcbio-03.png";
import image11 from "../Assets/portifolio/resort-alto-padrao-design-rustico-arcbio-01.png";
import image12 from "../Assets/portifolio/resort-alto-padrao-design-rustico-arcbio-04.png";
import image13 from "../Assets/portifolio/resort-alto-padrao-design-rustico-arcbio-02.png";
import image14 from "../Assets/portifolio/casa-rustica-litoranea-arcbio-01.jpg";
import image15 from "../Assets/portifolio/casa-rustica-litoranea-arcbio-02.jpg";
import image16 from "../Assets/portifolio/casa-rustica-litoranea-arcbio-03.jpg";

export const photosData = [
    {image: image1},
    {image: image2},
    {image: image3},
    {image: image4},
    {image: image5},
    {image: image6},
    {image: image7},
    {image: image8},
    {image: image9},
    {image: image10},
    {image: image11},
    {image: image12},
    {image: image13},
    {image: image14},
    {image: image15},
    {image: image16},
];
